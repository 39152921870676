<template>
  <div class="menus" style="padding: 12px 100px">
    <el-form
      :model="froms"
      :rules="rules"
      ref="froms"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="类型" prop="types">
            <el-select
              v-model="froms.types"
              @change="fnEdit"
              placeholder="请选择一级分类"
              style="width: 100%"
            >
              <el-option value="1" label="练习"></el-option>
              <el-option value="2" label="考试"></el-option>
              <el-option value="3" label="测试"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="froms.title"
              maxlength="30"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row> </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开始时间" prop="starttime">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="froms.starttime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间" prop="endtime">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="froms.endtime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="题库类型" prop="question">
            <el-radio-group v-model="froms.question">
              <el-radio :label="0">单库</el-radio>
              <el-radio :label="1">多库</el-radio>
              <el-radio :label="2">自选</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
                    <el-form-item label="题库单库" required v-if="froms.question == 0">
                         <el-select v-model="froms.questiontype"  placeholder="请选择题库" style="width:100%;">
                            <el-option  v-for="item in cities" :value="item.id" :key="item.id" :label="item.title"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="题库多库" required v-if="froms.question == 1">
                         <el-select v-model="froms.tid"  multiple  collapse-tags   placeholder="请选择" @change="aaaaa">
                            <el-option
                             v-for="itm in cities"
                            :key="itm.id"
                            :label="itm.title"
                            :value="itm.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                 </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="题库" prop="questionnum">
            <el-input
              placeholder="请输入问题数量"
              v-model="froms.questionnum"
              class="input-with-select"
            >
              <el-select
                v-model="froms.questiontype"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cities"
                  :value="item.id"
                  :key="item.id"
                  :label="item.title"
                ></el-option>
              </el-select>

              <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
              <template slot="prepend">数量</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="问题排序" prop="questionorder">
            <el-select
              v-model="froms.questionorder"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option :value="1" label="正序"></el-option>
              <el-option :value="2" label="随机"></el-option>
              <el-option :value="3" label="倒序"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="次数" prop="replynum">
            <el-input
              v-model="froms.replynum"
              placeholder="请输入次数"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row> </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="答题时间" prop="answertime">
            <el-input
              v-model="froms.answertime"
              placeholder="请输入答题时间"
              maxlength="5"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="每题分值" prop="figure">
            <el-input
              v-model="froms.figure"
              placeholder="请输入每题分值"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="及格分" prop="passing">
            <el-input
              v-model="froms.passing"
              placeholder="请输入及格分"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="良好分" prop="good">
            <el-input
              v-model="froms.good"
              placeholder="请输入良好分"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="优秀分" prop="fining">
            <el-input
              v-model="froms.fining"
              placeholder="请输入优秀分"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- <el-form-item label="积分兑换比率" required>
            <el-input v-model="froms.credit"></el-input>
          </el-form-item> -->
          <el-form-item label="限积分数" prop="creditDays">
            <el-input
              v-model="froms.creditDays"
              placeholder="请输入限积分数"
              maxlength="5"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="积分类型" prop="creditDay">
            <el-radio-group v-model="froms.creditDay">
              <el-radio :label="1">每天</el-radio>
              <el-radio :label="0">所有</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否转换积分" prop="creditType">
            <el-radio-group v-model="froms.creditType">
              <el-radio :label="0">转换</el-radio>
              <el-radio :label="1">不转换</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          size="small"
          type="primary"
          style="margin-top: 15px"
          @click="submitForm('froms')"
          >保存</el-button
        >
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>



<script>
import request from "@/utils/request.js";

export default {
  data() {
    return {
      htmls: "",
      froms: {},
      id: 0,
      yiji: [],
      erji: [],
      articleId: "", //跳转过来存放参数id
      cities: [], //题库数据存放
      ruleForm: {},
      replyForm: {}, // 答题
      rules: {
        types: [{ required: true, message: "请选择一级分类", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        starttime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endtime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        endtime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        question: [
          { required: true, message: "请选择题库类型", trigger: "blur" },
        ],
        questionnum: [
          { required: true, message: "题库和数量不能为空", trigger: "blur" },
        ],
        questionorder: [
          { required: true, message: "请选择问题排序", trigger: "blur" },
        ],
        replynum: [{ required: true, message: "请输入次数", trigger: "blur" }],
        answertime: [
          { required: true, message: "请输入答题时间", trigger: "blur" },
        ],
        figure: [{ required: true, message: "请输入分值", trigger: "blur" }],
        passing: [{ required: true, message: "请输入及格分", trigger: "blur" }],
        good: [{ required: true, message: "请输入良好分", trigger: "blur" }],
        fining: [{ required: true, message: "请输入优秀分", trigger: "blur" }],
        creditDay: [
          { required: true, message: "请选择积分类型", trigger: "blur" },
        ],
        creditDays: [
          { required: true, message: "请输入限积分数", trigger: "blur" },
        ],
        creditType: [
          { required: true, message: "请选择是否转换积分", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.info();
    this.bank();
  },
  methods: {
    info() {
      this.articleId = this.$route.query.id;
      if (this.articleId !== undefined) {
        this.particulars();
      }
    },
    aaaaa() {
      //    console.log(this.froms.tid)
    },
    //获取题库
    bank() {
      return request({
        url: "questionType/queryQuestionType",
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.cities = res.data;
        }
      });
    },
    //详情
    particulars() {
      return request({
        url: "reply/queryReplyById",
        method: "get",
        params: {
          replyId: this.articleId,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.froms = res.data;
          this.froms.creditType = Number(this.froms.creditType);
          // console.log(this.froms)
        }
      });
    },
    fnEdit(val) {
      return request({
        url: "articleCategory/" + val,
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.erji = res.data;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let total = this.froms.questionnum * this.froms.figure;
          if (this.froms.passing > total) {
            this.$message.error("及格分不能大于考试总分数");
            return;
          } else if (this.froms.good <= this.froms.passing) {
            this.$message.error("良好分必须大于及格分");
            return;
          } else if (this.froms.good > total) {
            this.$message.error("良好分不能大于考试总分数");
            return;
          } else if (this.froms.fining <= this.froms.good) {
            this.$message.error("优秀分必须大于良好分");
            return;
          } else if (this.froms.fining > total) {
            this.$message.error("优秀分不能大于考试总分数");
            return;
          } else if (this.froms.creditDays < total) {
            this.$message.error("限积分数不能低于考试总分数");
            return;
          }
          if (this.articleId === undefined) {
            return request({
              url: "reply/addReply",
              method: "post",
              data: this.froms,
            }).then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.$router.push({ path: "/Home/answer" });
              }
            });
          } else {
            return request({
              url: "reply/updateReply",
              method: "put",
              data: this.froms,
            }).then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push({ path: "/Home/answer" });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      return;
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}
.el-input-group__prepend {
  width: 196px;
}
.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  width: auto;
  height: auto;
}
.input-with-select .el-input-group__prepend {
  padding: 0;
  background-color: #ffffff !important;
}
.el-pagination {
  text-align: right;
}
.el-input-group__prepend {
  width: 165px;
}
.el-select {
  width: 165px;
}
</style>
 
